.mat-step-header {
  .mat-step-icon-selected,
  .mat-step-icon-state-edit {
    background-color: var(--primary-bg-color);
    color: var(--primary-font-color);
  }
  .mat-step-icon-state-done {
    background-color: green;
    color: white;
  }
}

.stepper-next-btn {
  background-color: var(--primary-button-bg-color);
  color: var(--primary-button-font-color);
}

.stepper-previous-btn {
  background-color: var(--secondary-button-bg-color);
  color: var(--secondary-button-font-color);
}
