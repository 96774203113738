.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary-button-bg-color);
}

span.mat-checkbox-label {
  font-weight: 400;
}

mat-checkbox {
  padding-bottom: 1.34375em;
}
