.mat-form-field-appearance-outline {
  .mat-form-field-infix {
    padding: 0.75em 0 0.75em 0 !important;
  }
}

.mat-form-field-appearance-outline {
  .mat-form-field-subscript-wrapper {
    padding: 0 !important;
  }
}

.mat-form-field-subscript-wrapper {
  margin-top: 0.4666666667em;
}

.mat-form-field.mat-focused .mat-form-field-label.mat-primary,
.mat-form-field-appearance-outline.mat-focused.mat-primary
  .mat-form-field-outline-thick,
.mat-form-field.mat-focused .mat-form-field-label.mat-accent,
.mat-form-field-appearance-outline.mat-focused.mat-accent
  .mat-form-field-outline-thick {
  color: var(--primary-bg-color);
}

mat-form-field {
  width: 100%;
}
