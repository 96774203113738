.mat-radio-outer-circle:not([disabled]) {
  border-color: var(--primary-bg-color);
  opacity: 0.5;
}

.mat-radio-label-content {
  @include rtl(padding, 0 0 0 6px, 0 6px 0 0);
}

mat-radio-button:not(:first-child) {
  @include rtl(padding, 0 0 0 0.5rem, 0 0.5rem 0 0);
}
