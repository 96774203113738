.header-wrapper {
  background-color: "#29557d";
  .header {
    padding-left: 0.625rem;
    height: 3.25rem;
  }

  .dropdowns {
    gap: 0.5rem;
  }

  .icons {
    gap: 1.25rem;
  }

  :host ::ng-deep {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }

  .accordion {
    height: 3.2rem;
    border-radius: 0.313rem;
    font-weight: 400;
    cursor: pointer;

    position: sticky;
    top: 0;
    z-index: 2;

    .headings-wrapper {
      padding: 0 1.5rem;
      height: 100%;
      width: 100%;

      .sort-icon {
        font-size: 0.9rem;
        display: inherit;
        margin-left: 5px;
        align-items: center;
      }

      .inner-wrapper {
        width: 100%;
        height: 3.2rem;

        .arrow {
          width: 5%;
          display: flex;
          justify-content: right;
        }
      }
    }
  }

  .icon {
    font-size: 1.2rem;
    padding-left: 0.313rem;
    display: flex;
    align-items: center;
  }

  .hover-arrow {
    color: rgb(132, 156, 180);
    display: none !important;
  }

  .sorting-icon-setting {
    display: flex;
    align-items: center;
    width: fit-content;
    height: inherit;

    &:hover {
      .hover-arrow {
        display: flex !important;
      }
    }
  }

  .sorting-icon-setting .display-icon {
    display: flex;
    align-items: center;
  }
}

.expanded-line-item-wrapper {
  background-color: " #f5f5f5";
  border-radius: 0.3125rem;
  font-size: 0.8rem;

  .row {
    min-height: 2.5rem;
  }

  .header {
    // padding: 0.8rem 0.563rem;
    // TODO: Updated it due to width issue
    padding: 0.8rem 0rem;

    color: #8b8b8b;
  }

  .pulled-item {
    border-radius: 0.3125rem;
    cursor: pointer;
  }

  .opened {
    border-left: 6px solid #5fa6ff !important;
    border-radius: 1px !important;
    padding-left: 5px !important;
  }

  .secondary-button {
    margin-bottom: 0.2rem;
    margin-top: 0.2rem;
  }
  .no-records {
    display: flex;
    height: 2rem;
  }
}

.secondary-header-wrapper {
  background-color: #d9d9d9;
  position: sticky;
  top: 0;
  z-index: 2;

  .accordion {
    height: 2.5rem;
    border-radius: 0.313rem;
    font-weight: 400;
    cursor: pointer;

    .headings-wrapper {
      padding: 0 1.5rem;
      height: 100%;
      width: 100%;

      .inner-wrapper {
        width: 100%;
        height: 3.2rem;

        .arrow {
          width: 5%;
          display: flex;
          justify-content: right;
          align-items: center;
        }
      }
    }
  }

  .line-items-wrapper {
    font-size: 0.82rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 0.313rem;
    border-left: 6px solid;
  }

  .icon {
    font-size: 1.2rem;
    padding-left: 0.313rem;
    display: flex;
    align-items: center;
  }

  .checkbox-styling {
    display: flex;
    justify-content: center;

    .checkbox {
      display: contents;
    }
  }
}
