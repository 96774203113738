@use "@angular/material/" as mat;

@import "@angular/material/theming";
@import "../helpers/variables";

@include mat.core();

$angular-primary: mat.define-palette($primary-default-pallet);
$angular-accent: mat.define-palette($accent-default-pallet);

$angular-warn: mat.define-palette(mat.$red-palette);

$angular-default-theme: mat.define-light-theme(
  (
    color: (
      primary: $angular-primary,
      accent: $angular-accent,
      warn: $angular-warn,
    ),
  )
);

@include mat.all-component-themes($angular-default-theme);

$angular-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $angular-primary,
      accent: $angular-accent,
      warn: $angular-warn,
    ),
  )
);

.dark-mode {
  @include mat.all-component-colors($angular-dark-theme);

  .mat-menu-content {
    color: var(--primary-font-color);
  }

  .mat-drawer-container .job-schedule-wrapper {
    color: #000;
  }

  .primary-color,
  u,
  a,
  .link {
    color: #fff !important;
  }

  .mat-standard-chip.mat-chip-with-trailing-icon {
    background: #616161 !important;
  }
}

.primary-color {
  color: var(--primary-bg-color);
}

.primary-app {
  background: var(--primary-bg-color) !important;
  color: var(--primary-font-color) !important;
}

.secondary-app {
  border-top: 0.163rem solid var(--primary-bg-color);
  background: var(--secondary-bg-color);
  color: var(--secondary-font-color);
}

.primary-button {
  background: var(--primary-button-bg-color);
  color: var(--primary-button-font-color);
}

.secondary-button {
  background: var(--secondary-button-bg-color);
  color: var(--secondary-button-font-color);
}
