.page-layout {
  @extend .flex-column;
  @extend .mat-elevation-z1;
  height: 100%;
  overflow-y: auto;

  .sub-header {
    @extend .flex-row;
    margin-bottom: 1rem;
  }

  app-data-table {
    height: 100%;
  }

  .add-button {
    z-index: 9999;
    width: max-content;
    border-radius: 0.3rem 0 0 0.3rem;
    position: absolute;
    top: 6%;
    right: 0.5rem;
    padding: 0 1rem;
  }
}

.dialog-header {
  @extend .flex-column;
  margin-bottom: 1.563rem;
}

// TODO: need to put somewhere else
.mat-toolbar {
  height: 3.2rem;
}

@media (max-width: 79.999rem) {
  .page-layout {
    .add-button {
      bottom: 5%;
      top: initial;
      border-radius: 2.5rem;
      mat-icon {
        font-weight: 600;
      }
    }
  }
}

.position-relative{
  position:  relative !important;
}
