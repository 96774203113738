$variables: (
  --primary-bg-color: var(--primary-bg-color, #ffffff),
  --primary-font-color: var(--primary-font-color, #000000),
  --secondary-bg-color: var(--secondary-bg-color, #e6e6e6),
  --secondary-font-color: var(--secondary-font-color, #fff),
  --primary-button-bg-color: var(--primary-button-bg-color, #001848),
  --primary-button-font-color: var(--primary-button-font-color, #ffffff),
  --secondary-button-bg-color: var(--secondary-button-bg-color, #e1e1e1),
  --secondary-button-font-color: var(--secondary-button-font-color, #000000),
  --font-family: var(--font-family),
);

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

@function get-text-color($color) {
  @if (lightness($color) > 50) {
    @return #000000;
  } @else {
    @return #ffffff;
  }
}

@mixin rtl($property, $ltr-value, $rtl-value) {
  #{$property}: $ltr-value !important;

  [dir="rtl"] & {
    #{$property}: $rtl-value !important;
  }
}
