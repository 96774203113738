.width-adjustment {
  display: flex;
  flex-direction: inherit;
  align-items: center;
  padding: 0px 10px;
}

.column-adjustment {
  display: flex;
  align-items: center;
}

.header-wrapper {
  background-color: "#29557d";
  .header {
    padding-left: 0.625rem;
    height: 3.25rem;
  }
  .accordion {
    height: 3rem;
    border-top-left-radius: 0.313rem;
    border-top-right-radius: 0.313rem;
    font-weight: 400;
    cursor: pointer;

    position: sticky;
    top: 0;
    z-index: 2;

    .headings-wrapper {
      // padding: 0 1.5rem;
      height: 100%;
      width: 100%;

      .inner-wrapper {
        width: 100%;
        height: 3.2rem;
      }
    }
  }
}
