@import "material-icons/iconfont/material-icons.scss";

html,
body {
  * {
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
  }

  *:hover {
    scrollbar-color: #909090cd transparent;
  }

  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.3rem transparent;
  }

  *::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
  }

  *:hover::-webkit-scrollbar-thumb {
    background: #9b9b9bd2;
    border-radius: 4px;
  }

  -webkit-backface-visibility: hidden;
  height: 100%;
}

body {
  margin: 0;
  *:not(mat-icon) {
    font-family: var(--font-family) !important;
  }
}

.icon-cursor-pointer {
  cursor: pointer;

  &:hover {
    transform: scale(1.15);
  }
}

.full-height {
  height: 100vh;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.space-between-center {
  @extend .flex-row;

  justify-content: space-between;
  align-items: center;
}

.space-between-start {
  @extend .flex-row;

  justify-content: space-between;
  align-items: flex-start;
}

.center-center {
  justify-content: center;
  align-items: center;
}

.start-center {
  @extend .flex-row;

  justify-content: flex-start;
  align-items: center;
}

.end-center {
  @extend .flex-row;

  justify-content: flex-end;
  align-items: center;
}

div.mat-sort-header-arrow {
  color: #fff;
}

.ellipse-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sticky-top {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 9;
}

// For mat-menu width
.mat-menu-panel {
  min-width: 18rem !important;
  max-width: 25rem !important;
}

.csv-download.mat-menu-panel {
  min-width: 11rem !important;
  max-width: 20rem !important;
}

.case-list-header {
  @extend .primary-app;
  border-radius: 0.313rem;
}

.return-sheet-pagination {
  .mat-paginator-container {
    min-height: 0;
    max-height: 1.688rem;
    padding: 0 2.5rem;
    flex-wrap: initial;

    .mat-paginator-range-actions {
      max-height: 1.688rem;
    }
  }
}

.custom-mat-checkbox {
  .mat-checkbox-inner-container {
    height: 14px;
    width: 14px;
  }
}

app-movement-wizard {
  .mat-horizontal-stepper-header-container {
    display: flex;
    width: 80%;
    align-self: center;
  }

  .filter-wrapper {
    overflow-y: auto;
    max-height: 15vh;
  }

  .filter-container {
    border: 1px solid #dbebff;
    border-radius: 5px;
    margin-bottom: 15px;
    &.unified {
      .chips-container {
        mat-icon {
          overflow: unset;
        }
      }
    }
  }
}
.no-style-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Use the color of the parent element */
}

.no-style-link:hover {
  text-decoration: none; /* Optional: Prevent underline on hover */
  color: inherit; /* Optional: Keep the same color on hover */
}

app-search-shared {
  .mat-form-field-wrapper {
    padding: 0.5rem 0rem;
  }
}
.icon-link-wrapper {
  display: flex;
  color: inherit;
}

.disabled {
  color: #8e8e8e !important;
  pointer-events: none !important;
}

.disabled-icon-with-click {
  color: #8e8e8e !important;
}
